import '&/spark/src/js/modules/bootstrap';
// import '&/spark/src/js/modules/feather';
// import '&/spark/src/js/modules/fullcalendar';
import '&/spark/src/js/modules/moment';
import '&/spark/src/js/modules/sidebar';
// import '&/spark/src/js/modules/splash';
// import '&/spark/src/js/modules/theme';
// import '&/spark/src/js/modules/toastr';
import '&/spark/src/js/modules/user-agent';

// Pages
// import '&/spark/src/js/modules/dragula';

// Charts
// import '&/spark/src/js/modules/chartjs';
// import '&/spark/src/js/modules/apexcharts';

// Forms
// import '&/spark/src/js/modules/daterangepicker';
import '&/spark/src/js/modules/datetimepicker';
// import '&/spark/src/js/modules/markdown';
import '&/spark/src/js/modules/mask';
// import '&/spark/src/js/modules/quill';
import '&/spark/src/js/modules/select2';
// import '&/spark/src/js/modules/validation';
import '&/spark/src/js/modules/wizard';

// Maps
// import '&/spark/src/js/modules/vector-maps';

// Tables
// import '&/spark/src/js/modules/datatables';

import jQuery from 'jquery';

function startBootstrap() {
  jQuery('[data-toggle="popover"]').popover();
  jQuery('[data-toggle="tooltip"]').tooltip();
}

document.addEventListener('DOMContentLoaded', startBootstrap);
