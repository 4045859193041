/* eslint class-methods-use-this: 0 */

import { Controller } from 'stimulus';
import $ from 'jquery';
import Rails from '@rails/ujs';

type replyParams = {
  'body': string
  'conversation_id': string
};

export default class extends Controller {
  // @ts-ignore
  declare conversationValue;

  static values = {
    conversation: String,
  }

  static targets = ['conversationId', 'body', 'messages', 'button'];

  connect() {
    this.buttonTarget.disabled = true;
    this.scroll();

    this.readConversation();
  }

  readConversation() {
    setTimeout(() => {
      $.ajax({
        url: this.conversationValue,
        type: 'PATCH',
        dataType: 'json',
        success: (data) => {
          if (data.unread_count === 0) {
            document.querySelector('#messages-indicator')?.remove();
          }
        },
      });
    }, 3000);
  }

  input() {
    if (this.bodyTarget.value.length) {
      Rails.enableElement(this.buttonTarget);
    } else {
      Rails.disableElement(this.buttonTarget);
    }
  }

  async submit(event: Event) {
    event.preventDefault();

    if (!this.bodyTarget.value) {
      return;
    }

    const params: replyParams = {
      body: this.bodyTarget.value,
      conversation_id: this.conversationIdTarget.value,
    };
    const response = await this.post(params);
    this.replaceMessages(response);
    this.resetForm();
    this.scrollBottom();
  }

  replaceMessages(html) {
    $(this.messagesTarget).empty().append(html);
  }

  resetForm() {
    this.bodyTarget.value = '';
    this.bodyTarget.focus();
    Rails.disableElement(this.buttonTarget);
  }

  scrollBottom() {
    const position = $(this.messagesTarget)[0].scrollHeight;
    $(this.messagesTarget).animate({ scrollTop: position }, 'fast');
  }

  scroll() {
    if (window.location.hash) {
      const target = document.querySelector(window.location.hash);
      target.scrollIntoView();
      target.classList.add('border', 'border-info');
    } else {
      this.scrollBottom();
    }
  }

  async post(params: replyParams) {
    return $.post('/member/messages/reply', { message: params });
  }

  get conversationIdTarget(): HTMLInputElement {
    return this.targets.find('conversationId') as HTMLInputElement;
  }

  get bodyTarget(): HTMLInputElement {
    return this.targets.find('body') as HTMLInputElement;
  }

  get messagesTarget(): HTMLInputElement {
    return this.targets.find('messages') as HTMLInputElement;
  }

  get buttonTarget(): HTMLInputElement {
    return this.targets.find('button') as HTMLInputElement;
  }
}
