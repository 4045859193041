import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import $ from 'jquery';

export default class extends Controller {
  // @ts-ignore
  declare conversationValue;

  static values = {
    conversation: String,
  }

  static targets = ['body', 'submit', 'messages'];

  connect() {
    this.bodyTarget.focus();
    this.submitTarget.disabled = true;
    this.scroll();

    this.readConversation();
  }

  readConversation() {
    setTimeout(() => {
      $.ajax({
        url: this.conversationValue,
        type: 'PATCH',
        dataType: 'json',
        success: (data) => {
          if (data.unread_count === 0) {
            document.querySelector('#messages-indicator')?.remove();
          }
        },
      });
    }, 3000);
  }

  input() {
    if (this.bodyTarget.value.length) {
      Rails.enableElement(this.submitTarget);
    } else {
      Rails.disableElement(this.submitTarget);
    }
  }

  scroll() {
    if (window.location.hash) {
      const target = document.querySelector(window.location.hash);
      target.scrollIntoView();
      target.classList.add('border', 'border-info');
    } else {
      const position = $(this.messagesTarget)[0].scrollHeight;
      $(this.messagesTarget).animate({ scrollTop: position }, 'fast');
    }
  }

  get bodyTarget(): HTMLInputElement {
    return this.targets.find('body') as HTMLInputElement;
  }

  get submitTarget(): HTMLInputElement {
    return this.targets.find('submit') as HTMLInputElement;
  }

  get messagesTarget(): HTMLInputElement {
    return this.targets.find('messages') as HTMLInputElement;
  }
}
