function takeDomain(host: string) {
  return host.split('.').slice(-2).join('.');
}

function isExternalLink(link: HTMLAnchorElement) {
  if (link.dataset.disableExternal || !link.href) {
    return false;
  }

  const locationDomain = takeDomain(window.location.host);
  const linkDomain = takeDomain(link.host);
  return locationDomain !== linkDomain;
}

function addClickEventListener(link: HTMLAnchorElement) {
  link.addEventListener('click', (e) => {
    window.location.href = `/url?q=${encodeURIComponent(link.href)}`;
    e.preventDefault();
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const links = document.querySelectorAll('a');
  links.forEach((value) => {
    if (isExternalLink(value)) {
      addClickEventListener(value);
    }
  });
});
