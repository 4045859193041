// %copyright%

import $ from 'jquery';
import Swal from 'sweetalert2';

function arrayInputsInitialize() {
  $('[data-toggle="array-input-add"]').on('click', (e) => {
    e.preventDefault();
    const targetName = $(e.target).data('target');
    const donor = $(`#${targetName}_donor`);
    const replica = donor.closest('.array-input-text-field').clone(true);
    replica.find('input').removeAttr('id');
    $(`.${targetName}`).append(replica);
  });

  $('[data-toggle="arrray-input-remove"]').on('click', (e) => {
    $(e.target).closest('.array-input-text-field').remove();
  });
}

function checkboxDetailOpenInitialize() {
  $('[data-toggle="checkbox-detail-open"]').on('change', (e) => {
    const self = $(e.target);
    const isChecked = self.is(':checked');
    const target = self.data('target');
    if (isChecked) {
      $(target).removeClass('hide');
      $(document).trigger('checkbox-detail:open', [target]);
    } else {
      $(target).addClass('hide');
      $(document).trigger('checkbox-detail:hide', [target]);
    }
  });
}

function sweetAlertInitialize() {
  $('[data-toggle="sweetalert"]').on('click', (e) => {
    e.stopPropagation();
    e.preventDefault();
    const target = $($(e.target).attr('href'));
    const title = target.data('title');
    const html = target.html();
    const type = target.data('type') || 'question';
    Swal.fire({
      type,
      width: 800,
      title,
      html,
    });
  });
}

function laborSystemsCheckbox() {
  $('input[name="labor_systems_checkbox"]').on('change', (e) => {
    const elm = $(e.target);
    const fieldset = $('fieldset.office_regulation_labor_systems');
    if (elm.val() === 'enabled') {
      fieldset.removeClass('disabled');
      fieldset.attr('disabled', false);
    } else {
      fieldset.find('.check_boxes').each((_, checkbox) => {
        if ($(checkbox).is(':checked')) {
          $(checkbox).triger('click');
        }
      });
      fieldset.addClass('disabled');
      fieldset.attr('disabled', true);
    }
  });
}

function radioDetailOpenInitialze() {
  const getRadioDetailOpens = (name) => {
    if (name) {
      return $(`[data-toggle="radio-detail-open"][name="${name}"]`);
    }

    return $('[data-toggle="radio-detail-open"]');
  };

  getRadioDetailOpens().on('change', (e) => {
    const details = getRadioDetailOpens($(e.target).attr('name'));
    const nowValue = $(e.target).val();
    details.each((_, detail) => {
      const self = $(detail);
      const target = $(self.data('target'));
      if (nowValue === self.attr('value')) {
        target.removeClass('hide');
      } else {
        target.addClass('hide');
      }
    });
  });
}

const DAYS = {
  2: 29,
  4: 30,
  6: 30,
  9: 30,
  11: 30,
};

function regulationsMultiDownloadable() {
  $('[data-toggle="multi-downloadable"]').on('click', async (e) => {
    e.preventDefault();
    const { target } = e;
    const files = JSON.parse(target.dataset.files);
    target.disabled = true;
    $(target).removeClass('btn-primary');
    $(target).addClass('btn-outline-primary');
    target.text = '労務書類データの生成中です。しばらくお待ちください…';

    // eslint-disable-next-line no-restricted-syntax
    for (const { filename, url } of files) {
      // eslint-disable-next-line no-await-in-loop
      const content = await fetch(url).then((res) => res.blob());
      const link = document.querySelector(
        `[data-download-target="${filename}"]`,
      );
      link.href = window.URL.createObjectURL(content);
      link.download = filename;
    }

    target.remove();
    $('.js-regulations-download-buttons').removeClass('hide');
  });

  $('[data-toggle="multi-downloadable"]').trigger('click');
}

function initializeAllowanceForm() {
  if (!$('#wage_regulation_allowances_form').length) {
    return;
  }

  function indentParagraphs(dom) {
    const indent = $(dom).val();
    const indentRoot = $(dom).closest('.indent-root');
    if (indent === 'paragraph') {
      indentRoot.removeClass('offset-1');
    } else {
      indentRoot.addClass('offset-1');
    }
  }

  function assignReadonlyToSelect(select, activeValue) {
    $(select)
      .find('option')
      .each((_i, option) => {
        if (activeValue) {
          $(option).attr('disabled', $(option).val() !== activeValue);
        } else {
          $(option).attr('disabled', false);
        }
      });
  }

  function controlParagprahInputs(paragraph) {
    $(paragraph)
      .find('.nested-allowance-paragraph-fields:not(.hide)')
      .each((index, row) => {
        const indentLevel = $(row).find('.js-indent-level');
        const buttonRemove = $(row).find('.button-remove-allowance');
        if (index === 0) {
          assignReadonlyToSelect(indentLevel, 'paragraph');
        } else {
          assignReadonlyToSelect(indentLevel, null);
        }
        if (index === 0) {
          buttonRemove.addClass('hide');
        } else {
          buttonRemove.removeClass('hide');
        }
      });
  }

  $(document).on('change', '.js-indent-level', (e) => {
    indentParagraphs(e.target);
  });

  $('.js-indent-level').each((_i, dom) => {
    indentParagraphs(dom);
  });

  $('.allowance_paragraphs').each((_i, dom) => {
    controlParagprahInputs(dom);
  });

  $(document).on('cocoon:after-insert', (_e, dom) => {
    controlParagprahInputs($(dom).closest('.allowance_paragraphs'));
  });

  $('#allowance_others_nested_items').on('cocoon:after-insert', (e, dom) => {
    if (e.target.id !== 'allowance_others_nested_items') return;

    $(dom).find('.button-add-paragraph').trigger('click');
  });
}

function initializeSalaryForm() {
  if (!$('#wage_regulation_salary_form').length) {
    return;
  }

  function dayOfMonth(monthElement, dayElement) {
    const day = $(dayElement);
    const selectedDay = parseInt(day.val(), 10);
    const monthVal = $(monthElement).val();
    const days = DAYS[monthVal] || 31;

    day.find('option').remove();
    for (let d = 1; d <= days; d += 1) {
      const selected = selectedDay === d ? ' selected' : '';
      day.append(`<option value="${d}"${selected}>${d}`);
    }
  }

  function dayByMonth(month) {
    return $(month).closest('.nested-rise-fields').find('.js-rise-day');
  }

  $(document).on('change', '.js-rise-month', (e) => {
    const day = dayByMonth(e.target);
    dayOfMonth(e.target, day);
  });

  $('.js-rise-month').each((_i, dom) => {
    const day = dayByMonth(dom);
    dayOfMonth(dom, day);
  });
}

document.addEventListener('DOMContentLoaded', () => {
  arrayInputsInitialize();
  checkboxDetailOpenInitialize();
  sweetAlertInitialize();
  laborSystemsCheckbox();
  radioDetailOpenInitialze();
  regulationsMultiDownloadable();
  initializeAllowanceForm();
  initializeSalaryForm();
});
