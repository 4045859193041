import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  // @ts-ignore
  declare recipientsUrlValue;

  static values = {
    recipientsUrl: String,
  }

  static targets = [
    'recipients',
    'recipientsCount',
    'deliveryConditionRegisteredAt',
    'deliveryConditionRegistrationCondition',
    'deliveryConditionSubscriptionStatus',
  ];

  keydown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.check(event);
    }
  }

  check = (event) => {
    event.preventDefault();

    const query = ((srcElement) => {
      if (srcElement.name === 'newsletter[query]') {
        return srcElement.value;
      }
      return srcElement.closest('.input-group').querySelector('input[name="newsletter[query]"]').value;
    })(event.srcElement);
    const checkButton = event.srcElement.closest('.input-group').querySelector('button');
    const indicator = checkButton.querySelector('i');

    const payload = {
      newsletter: {
        delivery_condition_registered_at: this.deliveryConditionRegisteredAtTarget.value,
        delivery_condition_registration_condition: this.deliveryConditionRegistrationConditionTarget.value,
        delivery_condition_subscription_status: this.deliveryConditionSubscriptionStatusTarget.value,
        query,
      },
    };

    indicator.classList.remove('fa-check', 'fa-times', 'text-success', 'text-danger');
    indicator.classList.add('fa-spinner', 'fa-spin');

    const self = this;
    $.ajax({
      url: this.recipientsUrlValue,
      type: 'GET',
      data: payload,
    })
      .then((data) => {
        if (data.result === 'Found') {
          indicator.classList.add('fa-check', 'text-success');
        } else {
          indicator.classList.add('fa-times', 'text-danger');
        }

        self.recipientsCountTarget.innerText = data.total_count;
        self.recipientsTarget.classList.remove('d-none');
      })
      .then(() => {
        indicator.classList.remove('fa-spinner', 'fa-spin');
      });
  }

  get recipientsTarget(): HTMLDivElement {
    return this.targets.find('recipients') as HTMLDivElement;
  }

  get recipientsCountTarget(): HTMLSpanElement {
    return this.targets.find('recipientsCount') as HTMLSpanElement;
  }

  get deliveryConditionRegisteredAtTarget(): HTMLInputElement {
    return this.targets.find('deliveryConditionRegisteredAt') as HTMLInputElement;
  }

  get deliveryConditionRegistrationConditionTarget(): HTMLSelectElement {
    return this.targets.find('deliveryConditionRegistrationCondition') as HTMLSelectElement;
  }

  get deliveryConditionSubscriptionStatusTarget(): HTMLSelectElement {
    return this.targets.find('deliveryConditionSubscriptionStatus') as HTMLSelectElement;
  }
}
