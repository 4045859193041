import { Controller } from 'stimulus';
import $ from 'jquery';
import { sort } from 'fast-sort';

export default class extends Controller {
  static targets = ['list', 'item', 'sortButton'];

  sort() {
    const sortedItems = this.sortByCreatedAt();
    this.switchSortStyle();
    this.removeItems();
    this.addItems(sortedItems);
    this.sortButtonTarget.blur();
  }

  private sortByCreatedAt(): HTMLElement[] {
    return this.isAsc()
      ? sort(this.itemTargets).desc([
        (item: HTMLElement) => item.dataset.createdAt,
      ])
      : sort(this.itemTargets).asc([
        (item: HTMLElement) => item.dataset.createdAt,
      ]);
  }

  private switchSortStyle() {
    const button = $(this.sortButtonTarget);
    if (this.isAsc()) {
      button.removeClass('asc');
      button.addClass('desc');
    } else {
      button.removeClass('desc');
      button.addClass('asc');
    }
  }

  private removeItems() {
    this.itemTargets.forEach((item) => $(item).remove());
  }

  private addItems(items: HTMLElement[]) {
    items.forEach((item) => {
      $(this.listTarget).append(item);
    });
  }

  private isAsc() {
    return $(this.sortButtonTarget).hasClass('asc');
  }

  get listTarget(): HTMLElement {
    return this.targets.find('list') as HTMLElement;
  }

  get itemTargets(): HTMLElement[] {
    return this.targets.findAll('item') as HTMLElement[];
  }

  get sortButtonTarget(): HTMLElement {
    return this.targets.find('sortButton') as HTMLElement;
  }
}
